import React, { Component } from "react"
import Button from "@mui/material/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Modal from "@mui/material/Modal"
import ArrowForwardIcon from "@mui/icons-material/Forward"
import ArrowForwardThinIcon from "@mui/icons-material/ArrowForward"
import WaveSvg from "../assets/images/Wave.svg"
import BreadCrumbs from "../components/bread-crumbs"
import "./letter-to-loved-ones.scss"

const breadCrumbsList = [
  {
    title: "Home",
    link: "/",
  },
]

class LetterToLovedOnes extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.analytics.page("Letter to Loved Ones")
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Letter To Loved Ones" />
        <BreadCrumbs
          currentPage={"Letter to Loved Ones"}
          breadCrumbsList={breadCrumbsList}
        />
        <div className="common-detailed-header">
          <div
            className="common-detailed-inner"
            style={{ alignItems: "center" }}
          >
            <div className="common-detailed-pattern-content">
              <h2 className="common-detailed-main-head loved-head">
                Letter to Loved Ones
              </h2>
            </div>
            <div className="common-detailed-image-side">
              <img
                className="dog-illustration-img"
                style={{ objectFit: "contain" }}
                src={require("../assets/images/dog-Illustration.svg")}
                alt="group-img"
              />
            </div>
          </div>
        </div>

        <div
          className="common-wave-wrapper letter-love-wrap"
          style={{ backgroundImage: `url(${WaveSvg})` }}
        >
          <div className="wave-bg">
            <div className="common-wave-inner letter-love">
              <h2 className="common-wave-sub-heading">
                We know this is hard, but you’re taking the right steps just by
                being here.
              </h2>
              <div className="letter-loved-para">
                <p>
                  This is a letter from us to you. What your family is facing is
                  all too common. You didn’t cause this, and it’s okay that you
                  need some help right now. You don’t have to do it alone.
                </p>
                <p>
                  The questions that keep you up at night, like “Why us?” aren’t
                  helping your family move forward. So you’ve begun to seek out
                  solutions.
                </p>
                <p>
                  You may not know what to do next. And asking your friends or
                  Dr. Google is less than helpful.
                </p>
                <p>
                  We want you to know this: You cannot cure or control your
                  loved one.
                </p>
                <p>How about starting with yourself?</p>
                <p>
                  While it may sound counterintuitive, it starts with taking a
                  step back. Focusing on improving your ability to cope, your
                  knowledge, and your communication skills helps keep you and
                  your family healthy and thriving.
                </p>
                <p>
                  Addiction hijacks the brain. This is why people continue to
                  use drugs even when they’re experiencing negative
                  consequences. There’s a blend of biological, psychological,
                  and social factors that contribute to continued, prolonged,
                  and problematic substance use. Often, problems with drugs
                  serve as a symptom of something deeper.
                </p>
                <p>
                  By working on yourself, you make it okay for the rest of the
                  family to uncover new things about themselves and slowly lift
                  their masks.
                </p>
                <p>
                  Confidant is here to guide you and your loved one through this
                  process, whether it’s through 1:1 appointments with
                  clinicians, virtual support groups, or learning with Alfie.
                  There is a path to thriving, and we can help you and your
                  loved ones get there.
                </p>
              </div>
              <div className="letter-loved-dark-text">
                <p> All the best, </p>
                <p>Jon Read </p>
                <p>Confidant Health, Founder</p>
              </div>
            </div>
          </div>
        </div>
        <div className="new-home-interested-wrapper loved-ones">
          <h2 className="interested-heading">
            Strategize with our Clinical Team
          </h2>
          <p className="interested-sub-heading">
            Get personalized advice from therapists, prescribers, and coaches.
            Each provider is trained to offer evidence-based best practices that
            treat the whole person.
          </p>
          <div className="list-gradient-bg">
            <div className="new-home-interested-inner">
              <div className="interested-list">
                <div className="single-interested-box">
                  <img
                    className="new-feature-image"
                    src={require("../assets/images/provider-prescriber.svg")}
                    alt="Providers & Prescriber"
                  />
                  <Button
                    href="/online-therapy/"
                    className="new-blue-link"
                    color="primary"
                  >
                    Providers & Prescribers <ArrowForwardThinIcon />
                  </Button>
                </div>

                <div className="single-interested-box">
                  <img
                    className="new-feature-image"
                    src={require("../assets/images/free-support.svg")}
                    alt="Support Groups"
                  />
                  <Button
                    href="/support-groups/"
                    className="new-blue-link"
                    color="primary"
                  >
                    Support Groups <ArrowForwardThinIcon />
                  </Button>
                </div>

                <div className="single-interested-box">
                  <img
                    className="new-feature-image"
                    src={require("../assets/images/self-directed.svg")}
                    alt="Self-directed Courses"
                  />
                  <Button
                    href="/how-to-guides/"
                    className="new-blue-link"
                    color="primary"
                  >
                    Self-directed Courses <ArrowForwardThinIcon />
                  </Button>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() => {}}
                  href={"/get-started"}
                  className="blue-arrow-btn"
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                >
                  <span className="button-label">Get Started</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="perks-wrap">
          <div className="perks-inner">
            <h2 className="perks-wrap-heading">
              We Can Help You and Your Family
            </h2>
            <p className="perks-wrap-para">
              We teamed up with epidemiologists, patient advocates,
              modern-recovery researchers and clinicians to establish both our
              technology and clinical practices. Here’s some ways we help:
            </p>
            <div className="perk-list">
              <div className="single-perk">
                <img
                  className="perk-icon"
                  src={require("../assets/images/heart-d.svg")}
                  alt="Icon"
                />
                <p className="perk-text">
                  A personalized program to help you improve your family
                  dynamics.
                </p>
              </div>
              <div className="single-perk">
                <img
                  className="perk-icon"
                  src={require("../assets/images/education-ico.svg")}
                  alt="Icon"
                />
                <p className="perk-text">
                  Relevant education and activities for your family’s situation.
                </p>
              </div>
              <div className="single-perk">
                <img
                  className="perk-icon"
                  src={require("../assets/images/book-bookmarked.svg")}
                  alt="Icon"
                />
                <p className="perk-text">
                  A guide to help you make hard decisions.
                </p>
              </div>
              <div className="single-perk">
                <img
                  className="perk-icon"
                  src={require("../assets/images/drag-ico.svg")}
                  alt="Icon"
                />
                <p className="perk-text">
                  Explicit do’s and don’ts to help you make the right decision
                  at hard times.
                </p>
              </div>
              <div className="single-perk">
                <img
                  className="perk-icon"
                  src={require("../assets/images/group-senior.svg")}
                  alt="Icon"
                />
                <p className="perk-text">
                  Link to a collaborative diverse clinical team.
                </p>
              </div>
              <div className="single-perk">
                <img
                  className="perk-icon"
                  src={require("../assets/images/clinic-ico.svg")}
                  alt="Icon"
                />
                <p className="perk-text">
                  A network of expert providers ready to help and available for
                  you to talk to about this difficult experience.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="modalStyle1"
          open={
            this.state.openModal ||
            (typeof window !== "undefined" &&
              window.location &&
              window.location.hash === "#get-started")
          }
          disableScrollLock={true}
        >
          <div />
        </Modal>
      </Layout>
    )
  }
}

export default LetterToLovedOnes
